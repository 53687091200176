import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { loginUser } from "../../Redux/Actions/authActions";

const Login = ({ loginUser, isAuthenticated, errors }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [loginFlag, setLoginFlag] = useState(false);

  const { email, password } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    loginUser(formData, loginFlag);
  };

  if (isAuthenticated) return <Navigate to="/dashboard" />;

  return (
    <section>
      <div className="flex flex-col items-center justify-center px-6 mx-auto my-14 lg:py-0">
        <div className="w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 bg-gray-800 border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            {loginFlag ? (
              <h1 className="text-xl font-bold leading-tight tracking-tight  md:text-2xl text-white">
                Sign in to your AstechBot account
              </h1>
            ) : (
              <h1 className="text-xl font-bold leading-tight tracking-tight  md:text-2xl text-white">
                Sign in to your GoldBit account
              </h1>
            )}
            <form
              className="space-y-4 md:space-y-6"
              noValidate
              onSubmit={onSubmit}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-white"
                >
                  Your email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                  placeholder="name@company.com"
                  value={email}
                  onChange={onChange}
                />
                {errors && errors.email && (
                  <p className="text-red-600 text-[13px]">{errors.email}</p>
                )}
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-white"
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                  value={password}
                  onChange={onChange}
                />
                {errors && errors.password && (
                  <p className="text-red-600 text-[13px]">{errors.password}</p>
                )}
              </div>
              {/* <div className="flex items-center justify-between">
                <div></div>
                {loginFlag ? (
                  <a
                    href="#!"
                    onClick={(e) => setLoginFlag(!loginFlag)}
                    className="text-sm font-medium text-primary-600 hover:underline text-primary-500 float-right"
                  >
                    Sign in with GoldBit
                  </a>
                ) : (
                  <a
                    href="#!"
                    onClick={(e) => setLoginFlag(!loginFlag)}
                    className="text-sm font-medium text-primary-600 hover:underline text-primary-500 float-right"
                  >
                    Sign in with AstechBot
                  </a>
                )}
              </div> */}
              <button
                type="submit"
                className="bg-blue-600 w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-primary-600 hover:bg-primary-700 focus:ring-primary-800"
              >
                Sign in with GoldBit
              </button>
              {/* {loginFlag ? (
                <button
                  type="submit"
                  className="bg-blue-600 w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-primary-600 hover:bg-primary-700 focus:ring-primary-800"
                >
                  Sign in with AstechBot
                </button>
              ) : (
                <button
                  type="submit"
                  className="bg-blue-600 w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-primary-600 hover:bg-primary-700 focus:ring-primary-800"
                >
                  Sign in with GoldBit
                </button>
              )} */}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(Login);
