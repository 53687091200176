import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getAccountByUser, swap } from "../../Redux/Actions/accountActions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { SwalAlert } from "../../Components/Common/SwalAlert";
import "sweetalert2/src/sweetalert2.scss";

import TetherUSD from "../../Assets/TetherUSD.png";
import SwapIco from "../../Assets/swap.png";
import WHT from "../../Assets/icon.png";
import BTC from "../../Assets/btc.png";
import ETH from "../../Assets/eth.png";
import BNB from "../../Assets/bnb.png";
import XRP from "../../Assets/xrp.png";
import DOGE from "../../Assets/doge.png";

import store from "../../Redux/store";
import { CLEAR_ERRORS } from "../../Redux/types";

const swalWithBootstrapButton = SwalAlert.mixin({
  customClass: {
    confirmButton: "btn btn-success",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: true,
});

const SwapPanel = ({
  getAccountByUser,
  swap,
  isAuthenticated,
  account,
  token,
  errors,
  symbol,
}) => {
  const [type, setType] = useState(0);
  const [amount, setAmt] = useState("");
  const [amount2, setAmt2] = useState("");
  const [base, setBaseCaption] = useState("");
  const [baseImg, setBaseImage] = useState();
  const [quote, setQuoteCaption] = useState("");
  const [quoteImg, setQuoteImage] = useState();
  const [mobile, setMobile] = useState(window.innerWidth <= 500);

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const tokenName = localStorage.account === "gb" ? "WHT" : "AST";
  useEffect(() => {
    if (isAuthenticated) getAccountByUser();
  }, [getAccountByUser, isAuthenticated]);

  useEffect(() => {
    if (symbol && symbol.value) {
      // Get Symbols One By One
      if (symbol.value === `${tokenName}USDT`) {
        setBaseCaption(`${tokenName === "WHT" ? "WHT" : tokenName}`);
        setBaseImage(WHT);
        setQuoteCaption("USDT");
        setQuoteImage(TetherUSD);
      } else if (symbol.value === "BTCUSDT") {
        setBaseCaption("BTC");
        setBaseImage(BTC);
        setQuoteCaption("USDT");
        setQuoteImage(TetherUSD);
      } else if (symbol.value === "ETHUSDT") {
        setBaseCaption("ETH");
        setBaseImage(ETH);
        setQuoteCaption("USDT");
        setQuoteImage(TetherUSD);
      } else if (symbol.value === "BNBUSDT") {
        setBaseCaption("BNB");
        setBaseImage(BNB);
        setQuoteCaption("USDT");
        setQuoteImage(TetherUSD);
      } else if (symbol.value === "XRPUSDT") {
        setBaseCaption("XRP");
        setBaseImage(XRP);
        setQuoteCaption("USDT");
        setQuoteImage(TetherUSD);
      } else if (symbol.value === "DOGEUSDT") {
        setBaseCaption("DOGE");
        setBaseImage(DOGE);
        setQuoteCaption("USDT");
        setQuoteImage(TetherUSD);
      } else if (symbol.value === `BTC${tokenName}`) {
        setBaseCaption("BTC");
        setBaseImage(BTC);
        setQuoteCaption(`${tokenName === "WHT" ? "WHT" : tokenName}`);
        setQuoteImage(WHT);
      } else if (symbol.value === `ETH${tokenName}`) {
        setBaseCaption("ETH");
        setBaseImage(ETH);
        setQuoteCaption(`${tokenName === "WHT" ? "WHT" : tokenName}`);
        setQuoteImage(WHT);
      } else if (symbol.value === `BNB${tokenName}`) {
        setBaseCaption("BNB");
        setBaseImage(BNB);
        setQuoteCaption(`${tokenName === "WHT" ? "WHT" : tokenName}`);
        setQuoteImage(WHT);
      } else if (symbol.value === `XRP${tokenName}`) {
        setBaseCaption("XRP");
        setBaseImage(XRP);
        setQuoteCaption(`${tokenName === "WHT" ? "WHT" : tokenName}`);
        setQuoteImage(WHT);
      } else if (symbol.value === `DOGE${tokenName}`) {
        setBaseCaption("DOGE");
        setBaseImage(DOGE);
        setQuoteCaption(`${tokenName === "WHT" ? "WHT" : tokenName}`);
        setQuoteImage(WHT);
      }
    }
  }, [symbol, tokenName]);

  const handleChange = (e) => {
    if (token && token.name) setAmt(e.target.value);
  };

  const handleChange2 = (e) => {
    if (token && token.name) setAmt2(e.target.value);
  };

  const handleSwitch = (e) => {
    if (type === 0) setType(1);
    else setType(0);
  };

  const handleSell = (e) => {
    e.preventDefault();

    if (!isAuthenticated) {
      swalWithBootstrapButton.fire(
        "Something went wrong",
        "Please login your account",
        "warning"
      );
      return;
    }

    if (amount === "") {
      swalWithBootstrapButton.fire(
        "Something went wrong",
        "Please enter the amount",
        "warning"
      );
      return;
    }

    const swapData = {
      amount: amount,
      amount2: (amount * (1 - 0.05) * token.curPrice).toFixed(2),
      type: 1,
    };

    swap(swapData, setAmt, setAmt2, getAccountByUser, swalWithBootstrapButton);
  };

  const handleBuy = (e) => {
    e.preventDefault();

    if (!isAuthenticated) {
      swalWithBootstrapButton.fire(
        "Something went wrong",
        "Please login your account",
        "warning"
      );
      return;
    }
    if (amount2 === "") {
      swalWithBootstrapButton.fire(
        "Something went wrong",
        "Please enter the amount",
        "warning"
      );
      return;
    }

    const swapData = {
      amount: amount2,
      amount2: ((amount2 * (1 - 0.05)) / token.curPrice).toFixed(2),
      type: 0,
    };

    swap(swapData, setAmt, setAmt2, getAccountByUser, swalWithBootstrapButton);
  };

  // If Errors
  if (errors && errors.swap) {
    swalWithBootstrapButton.fire("Swap Error", errors.swap, "error");
    store.dispatch({ type: CLEAR_ERRORS });
  }

  return (
    <div className="flex gap-3 mt-5">
      <ToastContainer autoClose={2000} />
      {mobile ? (
        <div className="flex flex-col">
          <label className="inline-flex items-center cursor-pointer m-auto">
            <input
              type="checkbox"
              value=""
              onChange={handleSwitch}
              className="sr-only peer"
            />
            <div className="relative w-11 h-6 bg-gray-400 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span className="ms-3 text-sm font-500">Buy/Sell</span>
          </label>
          {type === 0 ? (
            <div className="w-full pl-4 swap-panel flex flex-col mb-5 md:mb-0">
              <form noValidate onSubmit={handleBuy}>
                <div className="flex pt-3">
                  <div className="w-full">
                    <div className="flex justify-between bg-slate-800 border-[1px] border-slate-800 text-sm rounded p-2.5">
                      <div className="">Price</div>
                      <div className="flex gap-2">
                        <span>Market</span>
                        <span>{base}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex pt-3">
                  <div className="w-full flex">
                    <input
                      type="text"
                      id="success"
                      className="w-full bg-slate-800 border-[1px] border-slate-800 hover:border-[1px] hover:border-green-500 text-sm rounded p-2.5"
                      placeholder="Amount to buy"
                      onChange={handleChange2}
                      value={amount2}
                    />
                    <div className="flex justify-between bg-slate-800 border-[1px] border-slate-800 text-sm rounded p-2.5">
                      {quote}
                    </div>
                  </div>
                </div>
                {base === `${tokenName}` && quote === "USDT" ? (
                  <button
                    type="submit"
                    className="swap-btn w-full justify-center cursor-pointer py-2 m-auto mt-8 bg-lime-500 flex items-center rounded-lg"
                  >
                    <span>Buy</span>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="swap-btn w-full justify-center cursor-not-allowed py-2 m-auto mt-8 bg-lime-500 flex items-center rounded-lg"
                  >
                    <span>Buy</span>
                  </button>
                )}
              </form>
            </div>
          ) : (
            <div className="w-full pl-4 swap-panel flex flex-col mb-5 md:mb-0">
              <form noValidate onSubmit={handleSell}>
                <div className="flex pt-3">
                  <div className="w-full">
                    <div className="flex justify-between bg-slate-800 border-[1px] border-slate-800 text-sm rounded p-2.5">
                      <div className="">Price</div>
                      <div className="flex gap-2">
                        <span>Market</span>
                        <span>{base}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex pt-3">
                  <div className="w-full flex">
                    <input
                      type="text"
                      id="success"
                      className="w-full bg-slate-800 border-[1px] border-slate-800 hover:border-[1px] hover:border-green-500 text-sm rounded p-2.5"
                      placeholder="Amount to sell"
                      onChange={handleChange}
                      value={amount}
                    />
                    <div className="flex justify-between bg-slate-800 border-[1px] border-slate-800 text-sm rounded p-2.5">
                      {base}
                    </div>
                  </div>
                </div>
                {base === `${tokenName}` && quote === "USDT" ? (
                  <button
                    type="submit"
                    className="swap-btn w-full justify-center cursor-pointer py-2 m-auto mt-8 bg-red-600 flex items-center rounded-lg"
                  >
                    <span>Sell</span>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="swap-btn w-full justify-center cursor-not-allowed py-2 m-auto mt-8 bg-red-600 flex items-center rounded-lg"
                  >
                    <span>Sell</span>
                  </button>
                )}
              </form>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="w-[50%] pl-4 swap-panel flex flex-col mb-5 md:mb-0">
            <form noValidate onSubmit={handleSell}>
              <div className="flex pt-3">
                <div className="w-full">
                  <div className="flex justify-between bg-slate-800 border-[1px] border-slate-800 text-sm rounded p-2.5">
                    <div className="">Price</div>
                    <div className="flex gap-2">
                      <span>Market</span>
                      <span>{base}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex pt-3">
                <div className="w-full flex">
                  <input
                    type="text"
                    id="success"
                    className="w-full bg-slate-800 border-[1px] border-slate-800 hover:border-[1px] hover:border-green-500 text-sm rounded p-2.5"
                    placeholder="Amount to sell"
                    onChange={handleChange}
                    value={amount}
                  />
                  <div className="flex justify-between bg-slate-800 border-[1px] border-slate-800 text-sm rounded p-2.5">
                    {base}
                  </div>
                </div>
              </div>
              {base === `${tokenName}` && quote === "USDT" ? (
                <button
                  type="submit"
                  className="swap-btn w-full justify-center cursor-pointer py-2 m-auto mt-8 bg-red-600 flex items-center rounded-lg"
                >
                  <span>Sell</span>
                </button>
              ) : (
                <button
                  type="submit"
                  className="swap-btn w-full justify-center cursor-not-allowed py-2 m-auto mt-8 bg-red-600 flex items-center rounded-lg"
                >
                  <span>Sell</span>
                </button>
              )}
            </form>
          </div>
          <div className="w-[50%] swap-panel flex flex-col mb-5 md:mb-0">
            <form noValidate onSubmit={handleBuy}>
              <div className="flex pt-3">
                <div className="w-full">
                  <div className="flex justify-between bg-slate-800 border-[1px] border-slate-800 text-sm rounded p-2.5">
                    <div className="">Price</div>
                    <div className="flex gap-2">
                      <span>Market</span>
                      <span>{base}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex pt-3">
                <div className="w-full flex">
                  <input
                    type="text"
                    id="success"
                    className="w-full bg-slate-800 border-[1px] border-slate-800 hover:border-[1px] hover:border-green-500 text-sm rounded p-2.5"
                    placeholder="Amount to buy"
                    onChange={handleChange2}
                    value={amount2}
                  />
                  <div className="flex justify-between bg-slate-800 border-[1px] border-slate-800 text-sm rounded p-2.5">
                    {quote}
                  </div>
                </div>
              </div>
              {base === `${tokenName}` && quote === "USDT" ? (
                <button
                  type="submit"
                  className="swap-btn w-full justify-center cursor-pointer py-2 m-auto mt-8 bg-lime-500 flex items-center rounded-lg"
                >
                  <span>Buy</span>
                </button>
              ) : (
                <button
                  type="submit"
                  className="swap-btn w-full justify-center cursor-not-allowed py-2 m-auto mt-8 bg-lime-500 flex items-center rounded-lg"
                >
                  <span>Buy</span>
                </button>
              )}
            </form>
          </div>
        </>
      )}
    </div>
  );
};

SwapPanel.propTypes = {
  getAccountByUser: PropTypes.func.isRequired,
  swap: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  token: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.account,
  isAuthenticated: state.auth.isAuthenticated,
  token: state.token,
  errors: state.errors,
});

export default connect(mapStateToProps, { getAccountByUser, swap })(SwapPanel);
