import axios from 'axios'
import { CHART_LOADING, CLEAR_CANDLES, GET_CANDLES, GET_ERRORS, GET_TOKEN } from '../types'

export const getTokenInfo = () => (dispatch) => {
  axios
    // .get('http://localhost:5000/api/token')
    .get('https://api.gold-bit.io/api/token')
    .then((res) =>
      dispatch({
        type: GET_TOKEN,
        payload: res.data,
      }),
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      }),
    )
}

// Get Candles By Ticker
export const getCandlesByTicker = (ticker) => (dispatch) => {
  axios
    .get(`https://api.gold-bit.io/api/token/candles/${ticker}`)
    // .get(`http://localhost:5000/api/token/candles/${ticker}`)
    .then((res) => dispatch({ type: GET_CANDLES, payload: res.data }))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      }),
    )
}

// Clear Candles
export const clearCandles = () => {
  return {
    type: CLEAR_CANDLES,
  }
}

// Loading
export const setChartLoading = () => {
  return {
    type: CHART_LOADING,
  }
}
