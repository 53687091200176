import axios from "axios";
import jwt_decoded from "jwt-decode";
import setAuthToken from "../../Utils/setAuthToken";

import { CLEAR_ERRORS, GET_ERRORS, SET_CURRENT_USER } from "../types";

// Login Action
export const loginUser = (userData, loginFlag) => (dispatch) => {
  // if (loginFlag) {
  //   // AstechBot Login
  //   axios
  //     .post("https://testapi.astechbot.com.tr/login", userData)
  //     .then((res) => {
  //       const { token } = res.data;
  //       localStorage.setItem("token", token);
  //       localStorage.setItem("account", "as");
  //       setAuthToken(token);
  //       const decoded = jwt_decoded(token);
  //       dispatch(setCurrentUser({ decoded, token }));
  //     })
  //     .catch((err) =>
  //       dispatch({
  //         type: GET_ERRORS,
  //         payload: err.response.data,
  //       })
  //     );
  // } else {
  // GoldBit Login
  userData.site = 'cb';
  axios
    .post("https://api.gold-bit.io/api/users/login", userData)
    // .post("http://localhost:5000/api/users/login", userData)
    .then((res) => {
      const { token } = res.data;
      localStorage.setItem("token", token);
      localStorage.setItem("account", "gb");
      setAuthToken(token);
      const decoded = jwt_decoded(token);
      dispatch(setCurrentUser({ decoded, token }));
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
  // }
};

// Set Current User Action
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const clearError = () => {
  return {
    type: CLEAR_ERRORS,
  }
}

export const clearErrors = () => (dispatch) => {
  dispatch(clearError())
}

// LogOut Action
export const logOutUser = () => (dispatch) => {
  localStorage.removeItem("token");
  setAuthToken(false);
  dispatch(setCurrentUser({}));
};
