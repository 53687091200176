import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getTokenInfo } from "../../Redux/Actions/tokenActions";

const g = (min, max, decimals) => {
  return (Math.random() * (max - min) + min).toFixed(decimals);
};

const wht_usdt = [
  { price: 0.324, price2: 0.334, amount: 830.0, amount2: 1050.0 },
  { price: 0.318, price2: 0.316, amount: 1540.0, amount2: 550.0 },
  { price: 0.337, price2: 0.344, amount: 150.0, amount2: 250.0 },
  { price: 0.344, price2: 0.346, amount: 2140.0, amount2: 1550.0 },
  { price: 0.329, price2: 0.331, amount: 23115.0, amount2: 170.0 },
  { price: 0.339, price2: 0.338, amount: 7250.0, amount2: 1500.0 },
  { price: 0.345, price2: 0.344, amount: 8530.0, amount2: 580.0 },
  { price: 0.319, price2: 0.315, amount: 6410.0, amount2: 456.0 },
  { price: 0.308, price2: 0.305, amount: 300.0, amount2: 962.0 },
  { price: 0.319, price2: 0.311, amount: 1150.0, amount2: 450.0 },
  { price: 0.322, price2: 0.321, amount: 2100.0, amount2: 805.0 },
  { price: 0.345, price2: 0.342, amount: 51800.0, amount2: 4500.0 },
  { price: 0.338, price2: 0.334, amount: 65119.0, amount2: 80555.0 },
  { price: 0.329, price2: 0.335, amount: 140.0, amount2: 452.0 },
  { price: 0.341, price2: 0.324, amount: 59000.0, amount2: 89654.0 },
  { price: 0.348, price2: 0.338, amount: 29000.0, amount2: 4582.0 },
  { price: 0.317, price2: 0.364, amount: 11290.0, amount2: 25600.0 },
  { price: 0.307, price2: 0.304, amount: 165.0, amount2: 30000.0 },
  { price: 0.301, price2: 0.304, amount: 2445.0, amount2: 800.0 },
  // { price: 0.331, price2: 0.334, amount: 8240.0, amount2: 4050.0 },
  // { price: 0.324, price2: 0.334, amount: 830.0, amount2: 9650.0 },
  // { price: 0.318, price2: 0.316, amount: 1540.0, amount2: 4520.0 },
  // { price: 0.337, price2: 0.344, amount: 150.0, amount2: 8520.0 },
  // { price: 0.344, price2: 0.346, amount: 2140.0, amount2: 4500.0 },
  // { price: 0.329, price2: 0.331, amount: 23115.0, amount2: 6900.0 },
  // { price: 0.339, price2: 0.338, amount: 7250.0, amount2: 8528.0 },
  // { price: 0.345, price2: 0.344, amount: 8530.0, amount2: 4055.0 },
  // { price: 0.319, price2: 0.315, amount: 6410.0, amount2: 7852.0 },
  // { price: 0.308, price2: 0.305, amount: 300.0, amount2: 4585.0 },
  // { price: 0.319, price2: 0.311, amount: 1150.0, amount2: 4500.0 },
  // { price: 0.322, price2: 0.321, amount: 2100.0, amount2: 9650.0 },
  // { price: 0.345, price2: 0.342, amount: 51800.0, amount2: 4500.0 },
  // { price: 0.338, price2: 0.334, amount: 65119.0, amount2: 2500.0 },
  // { price: 0.329, price2: 0.335, amount: 140.0, amount2: 1500.0 },
  // { price: 0.341, price2: 0.324, amount: 59000.0, amount2: 8600.0 },
  // { price: 0.348, price2: 0.338, amount: 29000.0, amount2: 5280.0 },
  // { price: 0.317, price2: 0.364, amount: 11290.0, amount2: 5400.0 },
  // { price: 0.307, price2: 0.304, amount: 165.0, amount2: 1200.0 },
  // { price: 0.301, price2: 0.304, amount: 2445.0, amount2: 4500.0 },
  // { price: 0.331, price2: 0.334, amount: 8240.0, amount2: 4100.0 },
];

const stats = {
  BTC: {
    min: 0.0001,
    max: 1,
    decimals: 5,
  },
  ETH: {
    min: 0.0001,
    max: 20,
    decimals: 4,
  },
  BNB: {
    min: 0.001,
    max: 100,
    decimals: 3,
  },
  XRP: {
    min: 30,
    max: 10000,
    decimals: 0,
  },
  DOGE: {
    min: 200,
    max: 30000,
    decimals: 0,
  },
};

let timer;

const tokenName = localStorage.account === "gb" ? "WHT" : "AST";
const OrderBook = ({ getTokenInfo, token, symbol }) => {
  const [orders, setOrder] = useState(wht_usdt);
  const [mobile, setMobile] = useState(window.innerWidth <= 500);

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [])

  useEffect(() => {
    getTokenInfo();
  }, [getTokenInfo]);

  useEffect(() => {
    (async () => {
      if (token && token.name && symbol) {
        // Get Token Price
        let price = 0,
          symbol_ = `${tokenName}`;

        if (symbol.value !== `${tokenName}USDT`) {
          if (String(symbol.value).includes("BTC")) {
            price = await getTokenPriceByTicker("BTCUSDT");
          } else if (String(symbol.value).includes("ETH")) {
            price = await getTokenPriceByTicker("ETHUSDT");
          } else if (String(symbol.value).includes("BNB")) {
            price = await getTokenPriceByTicker("BNBUSDT");
          } else if (String(symbol.value).includes("XRP")) {
            price = await getTokenPriceByTicker("XRPUSDT");
          } else if (String(symbol.value).includes("DOGE")) {
            price = await getTokenPriceByTicker("DOGEUSDT");
          }
        }

        // Get Quote Coin
        if (String(symbol.value) !== `${tokenName}USDT`) {
          symbol_ = String(symbol.value).includes("USDT")
            ? String(symbol.value).replace("USDT", "")
            : String(symbol.value).replace(`${tokenName}`, "");
        }

        // Calc Rate If it's a pair of WHT
        if (
          String(symbol.value).includes(`${tokenName}`) &&
          !String(symbol.value).startsWith(`${tokenName}`)
        ) {
          price = price / token.curPrice;
        }

        // Generate Static Data
        const temp = [];
        if (String(symbol.value) !== `${tokenName}USDT`) {
          if (mobile) for (let i = 0; i < 6; i++) {
            temp.push({
              price: String(symbol.value).startsWith(`${tokenName}`)
                ? g(token.min, token.max, token.decimals)
                : g(price * 0.95, price * 1.05, 5),
              price2: String(symbol.value).startsWith(`${tokenName}`)
                ? g(token.min, token.max, token.decimals)
                : g(price * 0.95, price * 1.05, 5),
              amount:
                symbol_ === `${tokenName}`
                  ? g(50, 100000, 0)
                  : symbol_ === "BTC"
                    ? g(stats.BTC.min, stats.BTC.max, stats.BTC.decimals)
                    : symbol_ === "ETH"
                      ? g(stats.ETH.min, stats.ETH.max, stats.ETH.decimals)
                      : symbol_ === "BNB"
                        ? g(stats.BNB.min, stats.BNB.max, stats.BNB.decimals)
                        : symbol_ === "XRP"
                          ? g(stats.XRP.min, stats.XRP.max, stats.XRP.decimals)
                          : g(stats.DOGE.min, stats.DOGE.max, stats.DOGE.decimals),
              amount2:
                symbol_ === `${tokenName}`
                  ? g(50, 100000, 0)
                  : symbol_ === "BTC"
                    ? g(stats.BTC.min, stats.BTC.max, stats.BTC.decimals)
                    : symbol_ === "ETH"
                      ? g(stats.ETH.min, stats.ETH.max, stats.ETH.decimals)
                      : symbol_ === "BNB"
                        ? g(stats.BNB.min, stats.BNB.max, stats.BNB.decimals)
                        : symbol_ === "XRP"
                          ? g(stats.XRP.min, stats.XRP.max, stats.XRP.decimals)
                          : g(stats.DOGE.min, stats.DOGE.max, stats.DOGE.decimals),
            });
          } else
            for (let i = 0; i < 19; i++) {
              temp.push({
                price: String(symbol.value).startsWith(`${tokenName}`)
                  ? g(token.min, token.max, token.decimals)
                  : g(price * 0.95, price * 1.05, 5),
                price2: String(symbol.value).startsWith(`${tokenName}`)
                  ? g(token.min, token.max, token.decimals)
                  : g(price * 0.95, price * 1.05, 5),
                amount:
                  symbol_ === `${tokenName}`
                    ? g(50, 100000, 0)
                    : symbol_ === "BTC"
                      ? g(stats.BTC.min, stats.BTC.max, stats.BTC.decimals)
                      : symbol_ === "ETH"
                        ? g(stats.ETH.min, stats.ETH.max, stats.ETH.decimals)
                        : symbol_ === "BNB"
                          ? g(stats.BNB.min, stats.BNB.max, stats.BNB.decimals)
                          : symbol_ === "XRP"
                            ? g(stats.XRP.min, stats.XRP.max, stats.XRP.decimals)
                            : g(stats.DOGE.min, stats.DOGE.max, stats.DOGE.decimals),
                amount2:
                  symbol_ === `${tokenName}`
                    ? g(50, 100000, 0)
                    : symbol_ === "BTC"
                      ? g(stats.BTC.min, stats.BTC.max, stats.BTC.decimals)
                      : symbol_ === "ETH"
                        ? g(stats.ETH.min, stats.ETH.max, stats.ETH.decimals)
                        : symbol_ === "BNB"
                          ? g(stats.BNB.min, stats.BNB.max, stats.BNB.decimals)
                          : symbol_ === "XRP"
                            ? g(stats.XRP.min, stats.XRP.max, stats.XRP.decimals)
                            : g(stats.DOGE.min, stats.DOGE.max, stats.DOGE.decimals),
              });
            }
          setOrder(temp);
        } else {
          setOrder(wht_usdt);
        }

        // Clear Timer
        if (timer) clearInterval(timer);

        // Set Timer
        timer = setInterval(() => {
          setOrder((orders) => [
            ...orders,
            {
              price: String(symbol.value).startsWith(`${tokenName}`)
                ? g(token.min, token.max, token.decimals)
                : g(price * 0.95, price * 1.05, 5),
              price2: String(symbol.value).startsWith(`${tokenName}`)
                ? g(token.min, token.max, token.decimals)
                : g(price * 0.95, price * 1.05, 5),
              amount:
                symbol_ === `${tokenName}`
                  ? g(50, 100000, 0)
                  : symbol_ === "BTC"
                    ? g(stats.BTC.min, stats.BTC.max, stats.BTC.decimals)
                    : symbol_ === "ETH"
                      ? g(stats.ETH.min, stats.ETH.max, stats.ETH.decimals)
                      : symbol_ === "BNB"
                        ? g(stats.BNB.min, stats.BNB.max, stats.BNB.decimals)
                        : symbol_ === "XRP"
                          ? g(stats.XRP.min, stats.XRP.max, stats.XRP.decimals)
                          : g(stats.DOGE.min, stats.DOGE.max, stats.DOGE.decimals),
              amount2:
                symbol_ === `${tokenName}`
                  ? g(50, 100000, 0)
                  : symbol_ === "BTC"
                    ? g(stats.BTC.min, stats.BTC.max, stats.BTC.decimals)
                    : symbol_ === "ETH"
                      ? g(stats.ETH.min, stats.ETH.max, stats.ETH.decimals)
                      : symbol_ === "BNB"
                        ? g(stats.BNB.min, stats.BNB.max, stats.BNB.decimals)
                        : symbol_ === "XRP"
                          ? g(stats.XRP.min, stats.XRP.max, stats.XRP.decimals)
                          : g(stats.DOGE.min, stats.DOGE.max, stats.DOGE.decimals),
            },
          ]);
          setOrder((orders) => orders.filter((_, i) => i !== 0));
        }, 2000);
      }
    })();

    return () => clearInterval(timer);
  }, [token, symbol]);

  const getTokenPriceByTicker = async (ticker) => {
    const resp = await fetch(
      `https://api.binance.com/api/v3/ticker/price?symbol=${ticker}`
    );
    const price = await resp.json();
    return Number(price.price);
  };

  // const get_color = () => {
  //   const colors = ["text-red-600", "text-lime-500"];
  //   if (Math.random() > 0.5) return colors[0];
  //   else return colors[1];
  // };

  let sellOrders, buyOrders;
  const decimals = token && token.decimals ? token.decimals : 6;
  if (orders && orders.length > 0) {
    if (mobile) {
      sellOrders = orders.slice(0, 5).map((item, idx) => {
        return (
          <tr key={idx}>
            <td>
              <span className="text-red-600">{String(symbol.value).includes("BTC") ||
                String(symbol.value).includes("ETH")
                ? Number(item.price).toFixed(3)
                : Number(item.price).toFixed(decimals)}</span>
            </td>

            <td>
              {String(symbol.value) === `${tokenName}USDT` ||
                String(symbol.value) === "DOGEUSDT" ||
                String(symbol.value) === `DOGE${tokenName}`
                ? Number(item.amount).toFixed(2)
                : Number(item.amount).toFixed(5)}
            </td>
          </tr>
        );
      });

      buyOrders = orders.slice(0, 5).map((item, idx) => {
        return (
          <tr key={idx}>
            <td>
              <span className="text-lime-500">{String(symbol.value).includes("BTC") ||
                String(symbol.value).includes("ETH")
                ? Number(item.price2).toFixed(3)
                : Number(item.price2).toFixed(decimals)}</span>
            </td>

            <td>
              {String(symbol.value) === `${tokenName}USDT` ||
                String(symbol.value) === "DOGEUSDT" ||
                String(symbol.value) === `DOGE${tokenName}`
                ? Number(item.amount2).toFixed(2)
                : Number(item.amount2).toFixed(5)}
            </td>
          </tr>
        );
      });
    } else {
      sellOrders = orders.map((item, idx) => {
        return (
          <tr key={idx}>
            <td>
              <span className="text-red-600">{String(symbol.value).includes("BTC") ||
                String(symbol.value).includes("ETH")
                ? Number(item.price).toFixed(3)
                : Number(item.price).toFixed(decimals)}</span>
            </td>

            <td>
              {String(symbol.value) === `${tokenName}USDT` ||
                String(symbol.value) === "DOGEUSDT" ||
                String(symbol.value) === `DOGE${tokenName}`
                ? Number(item.amount).toFixed(2)
                : Number(item.amount).toFixed(5)}
            </td>

            <td>
              {String(symbol.value) === `${tokenName}USDT` ||
                String(symbol.value) === "DOGEUSDT" ||
                String(symbol.value) === `DOGE${tokenName}`
                ? (item.price * item.amount).toFixed(2)
                : (item.price * item.amount).toFixed(5)}
            </td>
          </tr>
        );
      });

      buyOrders = orders.map((item, idx) => {
        return (
          <tr key={idx}>
            <td>
              <span className="text-lime-500">{String(symbol.value).includes("BTC") ||
                String(symbol.value).includes("ETH")
                ? Number(item.price2).toFixed(3)
                : Number(item.price2).toFixed(decimals)}</span>
            </td>

            <td>
              {String(symbol.value) === `${tokenName}USDT` ||
                String(symbol.value) === "DOGEUSDT" ||
                String(symbol.value) === `DOGE${tokenName}`
                ? Number(item.amount2).toFixed(2)
                : Number(item.amount2).toFixed(5)}
            </td>

            <td>
              {String(symbol.value) === `${tokenName}USDT` ||
                String(symbol.value) === "DOGEUSDT" ||
                String(symbol.value) === `DOGE${tokenName}`
                ? (item.price2 * item.amount2).toFixed(2)
                : (item.price2 * item.amount2).toFixed(5)}
            </td>
          </tr>
        );
      });
    }

  }

  return (
    <div className="order-books">
      <table className="text-[11px] w-full font-[400] mt-2 text-center justify-center">
        <thead className="text-slate-600">
          <tr>
            <th>Price</th>
            <th>Amount</th>
            {!mobile && <th>Total</th>}
          </tr>
        </thead>
        <tbody className="text-center">{sellOrders}</tbody>
      </table>
      <table className="text-[11px] w-full font-[400] mt-3 text-center justify-center">
        <thead className="text-slate-600">
          <tr>
            <th></th>
            <th></th>
            {!mobile && <th></th>}
          </tr>
        </thead>
        <tbody className="text-center">{buyOrders}</tbody>
      </table>
    </div>
  );
};

OrderBook.propTypes = {
  getTokenInfo: PropTypes.func.isRequired,
  token: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  token: state.token,
});

export default connect(mapStateToProps, { getTokenInfo })(OrderBook);
