import { CHART_LOADING, CLEAR_CANDLES, GET_CANDLES, GET_TOKEN } from '../types'

const initialState = {
  name: null,
  min: null,
  max: null,
  curPrice: null,
  decimals: null,
  candles: null,
  loading: false,
}

export default function tokenReducer(state = initialState, action) {
  switch (action.type) {
    case CHART_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_TOKEN:
      return {
        ...state,
        name: action.payload.name,
        min: action.payload.min,
        max: action.payload.max,
        curPrice: action.payload.curPrice,
        decimals: action.payload.decimals,
        loading: false,
      }
    case GET_CANDLES:
      return {
        ...state,
        candles: action.payload,
        loading: false,
      }
    case CLEAR_CANDLES:
      return {
        ...state,
        candles: null,
        loading: false,
      }
    default:
      return state
  }
}
