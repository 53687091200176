import { GET_ACCOUNT } from "../types"

const initialState = {
  accountData: null,
  loading: false,
}

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNT:
        return {
            ...state,
            accountData: action.payload,
            loading: false,
        }
    default:
      return state
  }
}
