import React from "react";
import { Link } from "react-router-dom";

import logo from "../Assets/logo3.png";
import goldbit from "../Assets/icon.png";

const Footer = () => {
  return (
    <>
      <footer className="desktop bottom-0 left-0 h-[100px] md:h-[140px] w-full p-4 border-t-[1px] border-slate-700">
        <div className="container flex flex-col md:flex-row justify-between items-center">
          <span className="text-sm sm:text-center flex items-center mb-2">
            © 2024 &nbsp;
            <Link to="/" className="hover:underline">
              <img src={logo} className="w-36 h-28" alt="Logo" />
            </Link>
            &nbsp; All Rights Reserved.
          </span>
          <ul className="flex justify-between items-center text-sm sm:mt-0 float-right">
            <a href="https://gold-bit.io" rel="noreferrer" target={"_blank"}>
              <img src={goldbit} alt="togoldbit" className="w-24" />
            </a>
            &nbsp;&nbsp;
            <span className="text-[14px] font-300">for Staking</span>
          </ul>
        </div>
      </footer>
      <footer className="mobile h-[225px] md:h-[140px] w-full border-t-[1px] border-slate-700">
        <div className="container flex flex-col md:flex-row justify-between items-center">
          <span className="text-sm sm:text-center flex items-center mb-2">
            © 2024 &nbsp;
            <Link to="/" className="hover:underline">
              <img src={logo} className="w-36 h-28" alt="Logo" />
            </Link>
            &nbsp; All Rights Reserved.
          </span>
          <ul className="flex justify-between items-center text-sm sm:mt-0 float-right">
            <a href="https://gold-bit.io" rel="noreferrer" target={"_blank"}>
              <img src={goldbit} alt="togoldbit" className="w-24" />
            </a>
            &nbsp;&nbsp;
            <span className="text-[14px] font-300">for Staking</span>
          </ul>
        </div>
      </footer>
    </>
  );
};

export default Footer;
