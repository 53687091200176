import isEmpty from '../../Utils/isEmpty'
import { SET_CURRENT_USER } from '../types'

const initialState = {
  token: null,
  isAuthenticated: false,
  loading: true,
  user: {},
}

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        token: action.payload.token,
        isAuthenticated: !isEmpty(action.payload.decoded),
        loading: false,
        user: action.payload.decoded,
      }
    default:
      return state
  }
}
