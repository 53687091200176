export const GET_ERRORS = 'GET_ERRORS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const GET_ACCOUNT = 'GET_ACCOUNT'
export const GET_TOKEN = 'GET_TOKEN'
export const GET_CANDLES = 'GET_CANDLES'
export const CHART_LOADING = 'CHART_LOADING'
export const GET_HISTORIES = 'GET_HISTORIES'
export const CLEAR_CANDLES = 'CLEAR_CANDLES'
