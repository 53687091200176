import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { createChart, ColorType } from 'lightweight-charts'

import { getCandlesByTicker } from '../../Redux/Actions/tokenActions'

const Chart = ({ symbol, token, getCandlesByTicker }) => {
  const [ticker, setTicker] = useState('WHTUSDT')
  const chartContainerRef = useRef(null)
  const [mobile, setMobile] = useState(window.innerWidth <= 500);

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [])

  useEffect(() => {
    ; (async () => {
      if (
        symbol &&
        !String(symbol.value).includes('WHT') &&
        symbol.value !== ticker
      ) {
        getCandlesByTicker(String(symbol.value))
      }

      if (!String(symbol.value).includes('WHT') && token && !token.candles) {
        return
      }

      if (token && token.name && symbol) {
        setTicker(symbol.value)
        const candles = token.candles

        const handleResize = () => {
          chart.applyOptions({ width: chartContainerRef.current.clientWidth })
        }

        const chart = createChart(chartContainerRef.current, {
          layout: {
            background: { type: ColorType.Solid, color: 'rgb(15 23 42)' },
            textColor: 'white',
          },
        })

        chart.priceScale('right').applyOptions({
          borderColor: 'rgb(100 116 139)',
        })

        chart.timeScale().applyOptions({
          borderColor: 'rgb(100 116 139)',
        })

        chart.timeScale().fitContent()

        const newSeries = chart.addCandlestickSeries({
          priceScaleId: 'right',
          upColor: '#26a69a',
          downColor: '#ef5350',
          borderVisible: false,
          wickUpColor: '#26a69a',
          wickDownColor: '#ef5350',
        })

        let currentDate = get_current_date()

        const data = []

        if (String(symbol.value).includes('WHT')) {
          let initialDate = { day: 31, month: 12, year: 2023 }

          if (String(symbol.value) === 'WHTUSDT') {
            for (let i = 0; ; i++) {
              if (
                currentDate.year === initialDate.year &&
                currentDate.month === initialDate.month &&
                currentDate.day === initialDate.day
              ) {
                break
              }
              let businessDate = nextBusinessDay(initialDate)

              let open = g(token.min, token.max, token.decimals)
              let close = g(token.min, token.max, token.decimals)
              let high = g(token.max, token.max * 1.03, token.decimals)
              let delta = 0,
                low = 0
              if (open > close) {
                delta = high - open
                low = close - delta
              } else {
                delta = high - close
                low = open - delta
              }

              data.push({
                time: businessDate,
                open: open,
                high: high,
                low: low,
                close: close,
              })
              initialDate = businessDate
            }
          } else {
            // Calc prices
            let price = 0
            if (String(symbol.value).includes('BTC')) {
              price = await getTokenPriceByTicker('BTCUSDT')
            } else if (String(symbol.value).includes('ETH')) {
              price = await getTokenPriceByTicker('ETHUSDT')
            } else if (String(symbol.value).includes('BNB')) {
              price = await getTokenPriceByTicker('BNBUSDT')
            } else if (String(symbol.value).includes('XRP')) {
              price = await getTokenPriceByTicker('XRPUSDT')
            } else if (String(symbol.value).includes('DOGE')) {
              price = await getTokenPriceByTicker('DOGEUSDT')
            }

            price = price / token.curPrice

            for (let i = 0; ; i++) {
              if (
                currentDate.year === initialDate.year &&
                currentDate.month === initialDate.month &&
                currentDate.day === initialDate.day
              ) {
                break
              }
              let businessDate = nextBusinessDay(initialDate)

              let open = g(price * 0.98, price * 1.02, 3)
              let close = g(price * 0.98, price * 1.02, 3)
              let high = g(price * 1.02, price * 1.05, 3)
              let delta = 0,
                low = 0
              if (open > close) {
                delta = high - open
                low = close - delta
              } else {
                delta = high - close
                low = open - delta
              }

              data.push({
                time: businessDate,
                open: open,
                high: high,
                low: low,
                close: close,
              })
              initialDate = businessDate
            }
          }
        } else {
          if (candles) {
            for (let i = 0; i < candles.length; i++) {
              data.push({
                time: get_current_date2(Number(candles[i].timestamp)),
                open: candles[i].open,
                high: candles[i].high,
                low: candles[i].low,
                close: candles[i].close,
              })
            }
          }
        }

        newSeries.setData(data)

        const myPriceFormatter = Intl.NumberFormat(undefined, {
          notation: 'standard',
        }).format

        chart.applyOptions({
          timeScale: {
            fixLeftEdge: true,
            fixRightEdge: true,
            borderVisible: true,
          },
          crosshair: {
            // hide the horizontal crosshair line
            horzLine: {
              visible: true,
              labelVisible: true,
              color: '#26a69a',
            },
            // hide the vertical crosshair label
            vertLine: {
              labelVisible: true,
            },
          },
          // hide the grid lines
          grid: {
            vertLines: {
              visible: false,
            },
            horzLines: {
              color: 'rgba(255, 255, 255, 0.1)',
              visible: true,
            },
          },
          localization: {
            priceFormatter: myPriceFormatter,
          },
        })

        newSeries.priceScale().applyOptions({
          scaleMargins: {
            top: 0.1,
            bottom: 0.2,
          },
        })

        const volumeSeries = chart.addHistogramSeries({
          color: '#26a69a',
          priceFormat: {
            type: 'volume',
          },
          priceScaleId: '', // set as an overlay by setting a blank priceScaleId
          // set the positioning of the volume series
          scaleMargins: {
            top: 0.8, // highest point of the series will be 70% away from the top
            bottom: 0,
          },
        })

        volumeSeries.priceScale().applyOptions({
          scaleMargins: {
            top: 0.8, // highest point of the series will be 70% away from the top
            bottom: 0,
          },
        })

        const volumes = []

        if (String(symbol.value).includes('WHT')) {
          let initialDate2 = { day: 31, month: 12, year: 2023 }
          for (let i = 0; ; i++) {
            if (
              currentDate.year === initialDate2.year &&
              currentDate.month === initialDate2.month &&
              currentDate.day === initialDate2.day
            ) {
              break
            }
            let businessDate = nextBusinessDay(initialDate2)
            volumes.push({
              time: businessDate,
              value: g(100000, 10000000, 0),
              color: get_color(),
            })
            initialDate2 = businessDate
          }
        } else {
          if (candles) {
            for (let i = 0; i < candles.length; i++) {
              volumes.push({
                time: get_current_date2(candles[i].timestamp),
                value: candles[i].volume,
                color: get_color(),
              })
            }
          }
        }

        volumeSeries.setData(volumes)

        window.addEventListener('resize', handleResize)

        return () => {
          window.removeEventListener('resize', handleResize)

          chart.remove()
        }
      }
    })()
  }, [token, symbol, ticker, getCandlesByTicker])

  const get_current_date = () => {
    let nz_date_string = new Date().toLocaleString('en-US', {
      timeZone: 'Europe/Istanbul',
    })

    let date_ob = new Date(nz_date_string)

    // current date
    // adjust 0 before single digit date
    let date = ('0' + date_ob.getDate()).slice(-2)

    // current month
    let month = ('0' + (date_ob.getMonth() + 1)).slice(-2)

    // current year
    let year = date_ob.getFullYear()

    // prints date & time in YYYY-MM-DD HH:MM:SS format
    return { year: year, month: Number(month), day: Number(date) }
  }

  const get_current_date2 = (timestamp) => {
    let date_ob = new Date(timestamp * 1000)

    // current date
    // adjust 0 before single digit date
    let date = ('0' + date_ob.getDate()).slice(-2)

    // current month
    let month = ('0' + (date_ob.getMonth() + 1)).slice(-2)

    // current year
    let year = date_ob.getFullYear()

    return year + '-' + month + '-' + date
  }

  const get_color = () => {
    const colors = ['#26a69a', '#ef5350']
    if (Math.random() > 0.5) return colors[0]
    else return colors[1]
  }

  const nextBusinessDay = (time) => {
    var d = new Date()
    d.setUTCFullYear(time.year)
    d.setUTCMonth(time.month - 1)
    d.setUTCDate(time.day + 1)
    d.setUTCHours(0, 0, 0, 0)
    return {
      year: d.getUTCFullYear(),
      month: d.getUTCMonth() + 1,
      day: d.getUTCDate(),
    }
  }

  const g = (min, max, decimals) => {
    return Number((Math.random() * (max - min) + min).toFixed(decimals))
  }

  const getTokenPriceByTicker = async (ticker) => {
    const resp = await fetch(
      `https://api.binance.com/api/v3/ticker/price?symbol=${ticker}`,
    )
    const price = await resp.json()
    return Number(price.price)
  }

  return <div className={`h-[${mobile ? 350 : 500}px] overflow-hidden p-5`} ref={chartContainerRef}></div>
}

Chart.propTypes = {
  getCandlesByTicker: PropTypes.func.isRequired,
  token: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  token: state.token,
})

export default connect(mapStateToProps, { getCandlesByTicker })(Chart)
