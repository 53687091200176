import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import jwt_decoded from "jwt-decode";
import store from "./Redux/store";

import setAuthToken from "./Utils/setAuthToken";
import { logOutUser, setCurrentUser } from "./Redux/Actions/authActions";

import PrivateRoute from "./Components/Routing/PrivateRoute";

import Login from "./Pages/Auth/Login";
import TopBar from "./Layouts/TopBar";
import Landing from "./Layouts/Landing";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Footer from "./Layouts/Footer";
import Account from "./Pages/Account/Account";
import TrxHistory from "./Pages/TrxHistory/TrxHistory";

import "./App.css";

const App = ({ auth }) => {
  const { isAuthenticated } = auth;

  useEffect(() => {
    if (localStorage.token) {
      const token = localStorage.token;
      setAuthToken(localStorage.token);
      const decoded = jwt_decoded(localStorage.token);
      store.dispatch(setCurrentUser({ decoded, token }));

      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        store.dispatch(logOutUser());

        window.location.href = "/login";
      }
    }
  }, [isAuthenticated]);

  return (
    <BrowserRouter>
      <div className="bg-slate-900 text-white min-h-[100vh]">
        <TopBar />
        <div className="scrollbar overflow-y-auto">
          <Routes>
            <Route path="/login" element={<Login />}></Route>
            <Route
              path="/dashboard"
              element={<PrivateRoute component={Dashboard} />}
            ></Route>
            <Route path="/" element={<Landing />} />
            <Route
              path="/account"
              element={<PrivateRoute component={Account} />}
            ></Route>
            <Route
              path="/trx-history"
              element={<PrivateRoute component={TrxHistory} />}
            ></Route>
          </Routes>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

App.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(App);
