import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Select from "react-tailwindcss-select";

import { getTokenInfo, clearCandles } from "../../Redux/Actions/tokenActions";

const g = (min, max, decimals) => {
  return (Math.random() * (max - min) + min).toFixed(decimals);
};

let timer;

const StatusBar = ({
  getTokenInfo,
  clearCandles,
  token,
  symbol,
  setSymbol,
  setLoading,
}) => {
  const [value, setValue] = useState(0);
  const [change24, setChange24] = useState(0);
  const [high24, setHigh24] = useState(0);
  const [low24, setLow24] = useState(0);
  const [volume, setVolume] = useState(0);

  const tokenName = localStorage.account === "gb" ? "WHT" : "AST";
  let options;
  if (localStorage.account === "gb")
    options = [
      { value: "WHTUSDT", label: "WHT/USDT" },
      { value: "BTCUSDT", label: "BTC/USDT" },
      { value: "ETHUSDT", label: "ETH/USDT" },
      { value: "BNBUSDT", label: "BNB/USDT" },
      { value: "XRPUSDT", label: "XRP/USDT" },
      { value: "DOGEUSDT", label: "DOGE/USDT" },
      { value: "BTCWHT", label: "BTC/WHT" },
      { value: "ETHWHT", label: "ETH/WHT" },
      { value: "BNBWHT", label: "BNB/WHT" },
      { value: "XRPWHT", label: "XRP/WHT" },
      { value: "DOGEWHT", label: "DOGE/WHT" },
    ];
  else
    options = [
      { value: "ASTUSDT", label: "AST/USDT" },
      { value: "BTCUSDT", label: "BTC/USDT" },
      { value: "ETHUSDT", label: "ETH/USDT" },
      { value: "BNBUSDT", label: "BNB/USDT" },
      { value: "XRPUSDT", label: "XRP/USDT" },
      { value: "DOGEUSDT", label: "DOGE/USDT" },
      { value: "BTCWHT", label: "BTC/AST" },
      { value: "ETHWHT", label: "ETH/AST" },
      { value: "BNBWHT", label: "BNB/AST" },
      { value: "XRPWHT", label: "XRP/AST" },
      { value: "DOGEWHT", label: "DOGE/AST" },
    ];

  useEffect(() => {
    getTokenInfo();
  }, [getTokenInfo]);

  useEffect(() => {
    (async () => {
      if (timer) clearInterval(timer);

      let price = 0;
      if (symbol.value !== `${tokenName}USDT`) {
        if (String(symbol.value).includes("BTC")) {
          price = await getTokenPriceByTicker("BTCUSDT");
        } else if (String(symbol.value).includes("ETH")) {
          price = await getTokenPriceByTicker("ETHUSDT");
        } else if (String(symbol.value).includes("BNB")) {
          price = await getTokenPriceByTicker("BNBUSDT");
        } else if (String(symbol.value).includes("XRP")) {
          price = await getTokenPriceByTicker("XRPUSDT");
        } else if (String(symbol.value).includes("DOGE")) {
          price = await getTokenPriceByTicker("DOGEUSDT");
        }

        // Calc Rate If it's a pair of WHT
        if (
          String(symbol.value).includes(`${tokenName}`) &&
          !String(symbol.value).startsWith(`${tokenName}`)
        ) {
          price = price / token.curPrice;
        }

        timer = setInterval(() => {
          setValue(g(price * 0.999, price * 1.003, 4));
          setChange24(g(0.98, 1, 6));
          setHigh24(g((price * 0.97 + price * 1.03) / 2, price * 1.03, 4));
          setLow24(g(price * 0.97, (price * 0.97 + price * 1.03) / 2, 4));
          setVolume(g(9000000, 10000000));
        }, 3000);
      } else {
        if (token && token.name)
          timer = setInterval(() => {
            setValue(g(token.min, token.max, token.decimals));
            setChange24(g(0.98, 1, 6));
            setHigh24(
              g((token.min + token.max) / 2, token.max, token.decimals)
            );
            setLow24(g(token.min, (token.min + token.max) / 2, token.decimals));
            setVolume(g(140000, 150000));
          }, 3000);
      }
    })();
  }, [token, symbol, tokenName]);

  const handleChange = (value) => {
    setLoading(true);

    clearCandles();
    setSymbol(value);

    setTimeout(() => {
      setLoading(false);
    }, 2500);
  };

  const getTokenPriceByTicker = async (ticker) => {
    const resp = await fetch(
      `https://api.binance.com/api/v3/ticker/price?symbol=${ticker}`
    );
    const price = await resp.json();
    return Number(price.price);
  };

  return (
    <div className="status flex-col md:flex-row flex border-b-[1px] border-slate-700 md:pl-10 p-4 items-center justify-between gap-5">
      <div className="grid grid-cols-3 md:grid-cols-5 items-center gap-5">
        <div className="price flex flex-col items-center justify-center">
          <span className="text-green-500 font-[500] text-[14px]">{value}</span>
          <span className="text-[14px]">${value}</span>
        </div>
        <div className="24h flex flex-col items-center justify-center">
          <span className="text-[13px]">24h Change</span>
          <span className="text-red-500 font-[400] text-[14px]">
            {change24}%
          </span>
        </div>
        <div className="24h flex flex-col items-center justify-center">
          <span className="text-[13px]">24h High</span>
          <span className="font-[400] text-[14px]">{high24}</span>
        </div>
        <div className="24h flex flex-col items-center justify-center">
          <span className="text-[13px]">24h Low</span>
          <span className="font-[400] text-[14px]">{low24}</span>
        </div>
        <div className="24h flex flex-col items-center justify-center">
          <span className="text-[13px]">24h Volume</span>
          <span className="font-[400] text-[14px]">{volume} USDT</span>
        </div>
      </div>
      <div className="switch w-full md:w-[200px] bg-slate-800">
        <Select
          value={symbol}
          onChange={handleChange}
          options={options}
          isSearchable={true}
          classNames={{
            list: "scrollbar",
            searchBox:
              "w-full py-2 pl-8 text-sm text-white bg-slate-700 border border-gray-500 rounded focus:border-gray-200 focus:ring-0 focus:outline-none",
            searchContainer: "relative py-1 px-2.5 text-white",
            menuButton: ({ isDisabled }) =>
              `flex text-sm text-white border border-gray-500 rounded shadow-sm transition-all duration-300 focus:outline-none ${
                isDisabled
                  ? "bg-gray-200"
                  : "bg-slate-900 hover:border-gray-700 focus:border-blue-500 focus:ring focus:ring-blue-500/20"
              }`,
            menu: "absolute z-10 w-full bg-slate-800 shadow-lg border border-gray-500 rounded py-1 mt-1.5 text-sm text-white",
            listItem: ({ isSelected }) =>
              `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                isSelected
                  ? `text-white bg-blue-500`
                  : `text-slate-300 hover:bg-blue-100 hover:text-blue-500`
              }`,
          }}
        />
      </div>
    </div>
  );
};

StatusBar.propTypes = {
  getTokenInfo: PropTypes.func.isRequired,
  clearCandles: PropTypes.func.isRequired,
  token: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  token: state.token,
});

export default connect(mapStateToProps, { getTokenInfo, clearCandles })(
  StatusBar
);
