import axios from "axios";
import { GET_ACCOUNT, GET_ERRORS } from "../types";

import { setCurrentUser } from "./authActions";

// GET ACCOUNT BY USER
export const getAccountByUser = () => (dispatch) => {
  if (localStorage.account === "gb")
    axios
      .get("https://api.gold-bit.io/api/account")
      // .get("http://localhost:5000/api/account")
      .then((res) =>
        dispatch({
          type: GET_ACCOUNT,
          payload: res.data,
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        })
      );
  else
    axios
      .get("https://testapi.astechbot.com.tr/account")
      .then((res) =>
        dispatch({
          type: GET_ACCOUNT,
          payload: res.data,
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        })
      );
};

// Withdraw Fund
export const withdraw =
  (data, getAccountByUser, swalWithBootstrapButton) => (dispatch) => {
    if (localStorage.account === "gb")
      axios
        .post("https://api.gold-bit.io/api/account/withdraw", data)
        // .post("http://localhost:5000/api/account/withdraw", data)
        .then((res) => {
          swalWithBootstrapButton.fire(
            "Withdrawal Success!",
            "You withdrew successfully!",
            "success"
          );
          getAccountByUser();
          dispatch(setCurrentUser());
        })
        .catch((err) =>
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          })
        );
    else
      axios
        .post("https://testapi.astechbot.com.tr/withdraw", data)
        .then((res) => dispatch(setCurrentUser()))
        .catch((err) =>
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          })
        );
  };

// Send WTH to GoldBit
export const send =
  (data, getAccountByUser, swalWithBootstrapButton) => (dispatch) => {
    if (localStorage.account === "gb")
      axios
        .post("https://api.gold-bit.io/api/account/send", data)
        // .post("http://localhost:5000/api/account/send", data)
        .then((res) => {
          swalWithBootstrapButton.fire(
            "Transfer Success!",
            "You transferred successfully!",
            "success"
          );
          getAccountByUser();
          dispatch(setCurrentUser());
        })
        .catch((err) =>
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          })
        );
    else
      axios
        .post("https://testapi.astechbot.com.tr/send", data)
        .then((res) => dispatch(setCurrentUser()))
        .catch((err) =>
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          })
        );
  };

// Swap USDT and WHT
export const swap =
  (data, setAmt, setAmt2, getAccountByUser, swalWithBootstrapButton) =>
  (dispatch) => {
    if (localStorage.account === "gb")
      axios
        .post("https://api.gold-bit.io/api/account/swap", data)
        // .post("http://localhost:5000/api/account/swap", data)
        .then((res) => {
          swalWithBootstrapButton.fire(
            "Swap Success!",
            "You swapped successfully!",
            "success"
          );
          setAmt("");
          setAmt2("");
          getAccountByUser();
          dispatch(setCurrentUser());
          return true;
        })
        .catch((err) => {
          if (err.response)
            dispatch({
              type: GET_ERRORS,
              payload: err.response.data,
            });
        });
    else
      axios
        .post("https://testapi.astechbot.com.tr/swap", data)
        .then((res) => {
          setAmt("");
          setAmt2("");
          dispatch(setCurrentUser());
        })
        .catch((err) =>
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          })
        );
  };
