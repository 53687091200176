import React, { useEffect, useState } from "react";

import OrderBook from "./OrderBook";
import StatusBar from "./StatusBar";
import SwapPanel from "./SwapPanel";
import Chart from "./Chart";

import { InfinitySpin } from "react-loader-spinner";

const Dashboard = () => {
  const tokenName = localStorage.account === "gb" ? "WHT" : "AST";
  const [symbol, setSymbol] = React.useState({
    value: `${tokenName}USDT`,
    label: `WHT/USDT`,
  });
  const [loading, setLoading] = React.useState(false);
  const [mobile, setMobile] = useState(window.innerWidth <= 500);

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [])

  React.useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, []);
  return (
    <div>
      <StatusBar
        symbol={symbol}
        setSymbol={setSymbol}
        setLoading={setLoading}
      />
      {mobile ? <div
        className="dashboard w-full flex flex-col max-w-[1440px] m-auto"
        style={{ height: "calc(100vh - 300px)" }}
      >
        <div className="h-[350px]">
          {loading ? (
            <div className="w-full h-full flex items-center justify-center">
              <InfinitySpin className="m-auto" width="200" color="#4fa94d" />
            </div>
          ) : (
            <Chart symbol={symbol} />
          )}
        </div>
        <div className="flex gap-2">
          <div className="h-[270px] w-[65%]">
            <SwapPanel symbol={symbol} />
          </div>
          <div className="w-[35%] p-2 overflow-hidden">
            <OrderBook symbol={symbol} />
          </div>
        </div>
      </div> : <div
        className="dashboard w-full flex max-w-[1440px] m-auto"
        style={{ height: "calc(100vh - 210px)" }}
      >
        <div className="min-h-[200px] md:w-[25%] p-2 overflow-hidden">
          <OrderBook symbol={symbol} />
        </div>
        <div className="md:w-[75%] p-2 flex flex-col">
          <div className="h-[500px]">
            {loading ? (
              <div className="w-full h-full flex items-center justify-center">
                <InfinitySpin className="m-auto" width="200" color="#4fa94d" />
              </div>
            ) : (
              <Chart symbol={symbol} />
            )}
          </div>
          <div className="h-[350px]">
            <SwapPanel symbol={symbol} />
          </div>
        </div>
      </div>}
    </div>
  );
};

export default Dashboard;
