import axios from "axios";
import { GET_ERRORS, GET_HISTORIES } from "../types";

export const getAllHistories = () => (dispatch) => {
  if (localStorage.account === "gb")
    axios
      .get("https://api.gold-bit.io/api/trx")
      // .get("http://localhost:5000/api/trx")
      .then((res) =>
        dispatch({
          type: GET_HISTORIES,
          payload: res.data,
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        })
      );
  else
    axios
      .get("https://testapi.astechbot.com.tr/trx")
      .then((res) =>
        dispatch({
          type: GET_HISTORIES,
          payload: res.data,
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        })
      );
};
