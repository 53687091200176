import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import logo from "../Assets/logo3.png";

import { logOutUser } from "../Redux/Actions/authActions";

const TopBar = ({ logOutUser, auth, isAuthenticated }) => {
  const logOut = (e) => {
    e.preventDefault();

    logOutUser();
  };

  const authLinks = (
    <ul>
      <li>
        <a href="#!" onClick={logOut}>
          LogOut
        </a>
      </li>
    </ul>
  );

  const guestLinks = (
    <ul>
      <li>
        <Link to="/login">Login</Link>
      </li>
    </ul>
  );

  return (
    <div className="topbar top-0 left-0 px-5 h-[150px] md:h-[100px] flex flex-col md:flex-row justify-between items-center border-b-[1px] border-slate-700 text-[14px] font-[400]">
      <div className="logo">
        <Link to={"/"}>
          <img
            src={logo}
            alt={"logo"}
            className="md:w-56 md:h-32 md:mt-2 w-48 h-32"
          />
        </Link>
      </div>

      <div className="flex items-center justify-around w-full absolute top-[110px] md:top-auto md:relative">
        <div className="menu flex gap-3 md:gap-10">
          <Link to={"/dashboard"}>Swap</Link>
          <Link to={"/account"}>Account</Link>
          <Link to={"/trx-history"}>Transaction</Link>
        </div>

        <div className="right-menu right-1 float-right">
          {isAuthenticated ? authLinks : guestLinks}
        </div>
      </div>
    </div>
  );
};

TopBar.propTypes = {
  logOutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { logOutUser })(TopBar);
