import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataTable, { createTheme } from 'react-data-table-component'

import { getAllHistories } from '../../Redux/Actions/trxActions'

createTheme(
  'solarized',
  {
    text: {
      primary: 'white',
      secondary: 'white',
    },
    background: {
      default: 'transparent',
    },
  },
  'dark',
)

const shortenAddress = (address, chars) => {
  return `${address.slice(0, chars)}...${address.slice(-chars)}`
}

const TrxHistory = ({ getAllHistories, isAuthenticated, history }) => {
  useEffect(() => {
    getAllHistories()
  }, [getAllHistories])

  const { histories } = history

  if (!isAuthenticated) return <Navigate to={'/login'} />

  const d_data = [],
    sendData = [],
    swapData = []
  // Deposit Dataset
  if (histories && histories.length > 0) {
    for (let i = 0; i < histories.length; i++) {
      if (histories[i].type === 1 || histories[i].type === 2) {
        // Deposit / Withdraw
        d_data.push({
          type: histories[i].type === 1 ? 'Deposit' : 'Withdraw',
          network: histories[i].network === 0 ? 'BEP-20' : 'TRC-20',
          address: shortenAddress(histories[i].address, 5),
          amount: histories[i].amount,
          status:
            histories[i].status === 0
              ? 'Pending'
              : histories[i].status === 1
              ? 'Success'
              : 'Rejected',
          when: new Intl.DateTimeFormat('tr-TR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }).format(new Date(histories[i].created_at).getTime()),
        })
      }
      if (histories[i].type === 3 || histories[i].type === 4) {
        // Send
        sendData.push({
          type:
            histories[i].type === 3
              ? 'Whitelight -> GoldBit'
              : 'GoldBit -> Whitelight',
          amount: histories[i].amount,
          status:
            histories[i].status === 0
              ? 'Pending'
              : histories[i].status === 1
              ? 'Success'
              : 'Rejected',
          when: new Intl.DateTimeFormat('tr-TR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }).format(new Date(histories[i].created_at).getTime()),
        })
      }
      if (histories[i].type === 5 || histories[i].type === 6) {
        // Swap
        swapData.push({
          type: histories[i].type === 5 ? 'USDT -> WHT' : 'WHT -> USDT',
          amount: histories[i].amount,
          status:
            histories[i].status === 0
              ? 'Pending'
              : histories[i].status === 1
              ? 'Success'
              : 'Rejected',
          when: new Intl.DateTimeFormat('tr-TR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }).format(new Date(histories[i].created_at).getTime()),
        })
      }
    }
  }

  const d_cols = [
    {
      name: 'Type',
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: 'Network',
      selector: (row) => row.network,
      sortable: true,
    },
    {
      name: 'Address',
      selector: (row) => row.address,
    },
    {
      name: 'Amount',
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: 'When',
      selector: (row) => row.when,
      sortable: true,
    },
  ]

  const send_cols = [
    {
      name: 'Type',
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: 'When',
      selector: (row) => row.when,
      sortable: true,
    },
  ]

  const swap_cols = [
    {
      name: 'Type',
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: 'When',
      selector: (row) => row.when,
      sortable: true,
    },
  ]

  return (
    <div className="p-2 md:p-14">
      <DataTable
        title="Deposit / Withdraw (USDT)"
        columns={d_cols}
        data={d_data}
        pagination
        theme="solarized"
      />
      <DataTable
        title="Send History (WHT)"
        columns={send_cols}
        data={sendData}
        pagination
        theme="solarized"
      />
      <DataTable
        title="Swap History"
        columns={swap_cols}
        data={swapData}
        pagination
        theme="solarized"
      />
    </div>
  )
}

TrxHistory.propTypes = {
  getAllHistories: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  history: state.history,
})

export default connect(mapStateToProps, { getAllHistories })(TrxHistory)
