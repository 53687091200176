import { GET_HISTORIES } from '../types'

const initialState = {
  histories: null,
  loading: false,
}

export default function trxReducer(state = initialState, action) {
  switch (action.type) {
    case GET_HISTORIES:
      return {
        ...state,
        histories: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
