import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Select from "react-tailwindcss-select";

import CopyToClipboard from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { SwalAlert } from "../../Components/Common/SwalAlert";
import "sweetalert2/src/sweetalert2.scss";

import {
  getAccountByUser,
  withdraw,
  send,
} from "../../Redux/Actions/accountActions";
import { clearErrors } from "../../Redux/Actions/authActions";

const swalWithBootstrapButton = SwalAlert.mixin({
  customClass: {
    confirmButton: "btn btn-success",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: true,
});

const shortenAddress = (address, chars) => {
  if (address) return `${address.slice(0, chars)}...${address.slice(-chars)}`;
};

const options = [
  { value: 0, label: "USDT" },
  { value: 1, label: "WHT" },
];

const Account = ({
  getAccountByUser,
  clearErrors,
  withdraw,
  send,
  account,
  errors,
  isAuthenticated,
}) => {
  const [formData, setFormData] = useState({
    address: "",
    amount: 0,
    amount2: 0,
    type: 3,
  });
  const [copied, setCopy] = useState(false);
  const [type, setTokenType] = useState({ value: 1, label: "WHT" });

  const { address, amount, amount2 } = formData;
  const notify = () => toast("Success");

  if (copied) toast("Copied");

  useEffect(() => {
    getAccountByUser();
  }, [getAccountByUser]);

  const { accountData } = account;

  const handleWithdraw = (e) => {
    e.preventDefault();

    if (amount === 0) {
      swalWithBootstrapButton.fire(
        "Something went wrong",
        "Please enter the amount",
        "warning"
      );
    }

    withdraw(formData, getAccountByUser, swalWithBootstrapButton);
    setFormData({ ...formData, amount: 0 });
  };

  const handleSend = (e) => {
    e.preventDefault();

    if (amount2 === 0) {
      swalWithBootstrapButton.fire(
        "Something went wrong",
        "Please enter the amount",
        "warning"
      );
    }

    if (type.value === 1) {
      // WHT
      formData.type = 3;
    } else {
      // USDT
      formData.type = 7;
    }

    send(formData, getAccountByUser, swalWithBootstrapButton);
    setFormData({ ...formData, amount2: 0 });
  };

  // If Errors
  if (errors && errors.send) {
    swalWithBootstrapButton.fire("Transfer Error", errors.send, "error");
    clearErrors();
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onChange = (value) => {
    setTokenType(value);
  };

  if (!isAuthenticated) return <Navigate to={"/login"} />;

  return (
    <section>
      <ToastContainer />
      <div className="portfolio p-6">
        <div className="flex flex-col pl-8 border-b-[2px] border-slate-500">
          <div className="text-[19px] font-[400]">Total Balance</div>
          <div className="balance flex gap-6">
            <span className="balance text-[25px] font-[400]">
              <span className="text-lime-500">
                {accountData
                  ? Number(
                      localStorage.account === "gb"
                        ? accountData.wht_balance_wl
                        : accountData.ast_balance_wl
                    ).toFixed(2)
                  : "0.00"}
              </span>{" "}
              <span className="text-red-500">WHT</span>
            </span>
            <span className="balance text-[25px] font-[400] mb-2">
              <span className="text-lime-500">
                {accountData ? Number(accountData.balance).toFixed(2) : "0.00"}
              </span>{" "}
              <span className="text-red-500">USDT</span>
            </span>
          </div>
        </div>
        <div className="forms md:grid-cols-3 px-16 mt-5 grid grid-cols-1 gap-3">
          <div className="deposit-form max-w-sm">
            <div className="text-[20px] text-slate-300">Deposit Address:</div>
            <br />
            <div className="flex gap-3">
              <span className="text-lime-500">BEP20: </span>{" "}
              <span className="text-slate-200">
                {accountData
                  ? shortenAddress(
                      localStorage.account === "gb"
                        ? accountData.walletBep
                        : accountData.bep_addr,
                      5
                    )
                  : "-----"}
              </span>
              <CopyToClipboard
                text={
                  accountData && localStorage.account === "gb"
                    ? accountData.walletBep
                    : accountData.bep_addr
                }
                onCopy={(e) => setCopy(true)}
              >
                <img
                  className="w-5 h-5 cursor-pointer"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC9SURBVHgB7ZWhDsIwEIb/LhWgZ5mFZwCJ5xnwvBB2PAMaPYueBTsLsvwnICWhWS9pEUu/5F8vu6b/9dK0QGbMO3DO1Rx20POkzsaYx69k5cUNtRYvpWbUAgGsF8/lw0pOSIgNJdgyqaqBDmnXjUUOowbkQNXQ01FtjIEs3rKaDpFw13t4B0eokJliUAyKwdQMBmTgY8BLredwRGK+WkSTKxJjR/IbXsErxLOkev+HCc3kwlvoXzThwk7c8S9eof8soAC+q+gAAAAASUVORK5CYII="
                  alt="copy"
                />
              </CopyToClipboard>
            </div>
            <div className="flex gap-3">
              <span className="text-lime-500">TRC20: </span>{" "}
              <span className="text-slate-200">
                {accountData
                  ? shortenAddress(
                      localStorage.account === "gb"
                        ? accountData.walletTrc
                        : accountData.trc_addr,
                      5
                    )
                  : "-----"}
              </span>
              <CopyToClipboard
                text={
                  accountData && localStorage.account === "gb"
                    ? accountData.walletTrc
                    : accountData.trc_addr
                }
                onCopy={(e) => setCopy(true)}
              >
                <img
                  className="w-5 h-5 cursor-pointer"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC9SURBVHgB7ZWhDsIwEIb/LhWgZ5mFZwCJ5xnwvBB2PAMaPYueBTsLsvwnICWhWS9pEUu/5F8vu6b/9dK0QGbMO3DO1Rx20POkzsaYx69k5cUNtRYvpWbUAgGsF8/lw0pOSIgNJdgyqaqBDmnXjUUOowbkQNXQ01FtjIEs3rKaDpFw13t4B0eokJliUAyKwdQMBmTgY8BLredwRGK+WkSTKxJjR/IbXsErxLOkev+HCc3kwlvoXzThwk7c8S9eof8soAC+q+gAAAAASUVORK5CYII="
                  alt="copy"
                />
              </CopyToClipboard>
            </div>
            <div className="mt-10 info text-[13px] border-lime-500 border-l-[2px]">
              &nbsp;&nbsp; Please wait... It may take a few minutes or one hour
              <br />
              &nbsp;&nbsp; Your fund is safe
            </div>
          </div>
          <div className="withdraw-form max-w-sm">
            <div className="text-[20px] text-slate-300">
              Withdraw your Funds:
            </div>
            <br />
            <form onSubmit={handleWithdraw} className="flex flex-col gap-3">
              <div>
                <label
                  htmlFor="address"
                  className="block mb-2 text-sm font-[400] text-white"
                >
                  Your address:
                </label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  className="text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-600 border-gray-500 placeholder-gray-400 text-white"
                  placeholder="BEP20 / TRC20 address"
                  required
                  value={address}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label
                  htmlFor="amount"
                  className="block mb-2 text-sm font-[400] text-white"
                >
                  Amount to withdraw:
                </label>
                <input
                  type="number"
                  name="amount"
                  id="amount"
                  className="text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-600 border-gray-500 placeholder-gray-400 text-white"
                  placeholder="10"
                  required
                  min={10}
                  value={amount}
                  onChange={handleChange}
                />
              </div>
              <button
                type="submit"
                className="btn bg-lime-500 h-[40px] rounded-[6px] mt-4"
              >
                Withdraw
              </button>
            </form>
            <div className="mt-10 info text-[13px] border-lime-500 border-l-[2px]">
              &nbsp;&nbsp; Please wait... Administrator will approve your
              request.
              <br />
              &nbsp;&nbsp; Your fund is safe
            </div>
          </div>
          <div className="send-form max-w-sm">
            <div className="text-[20px] text-slate-300">
              Send WHT to{" "}
              {localStorage.account === "gb" ? "CyberGold" : "AstechBot"}:
            </div>
            <br />
            <form onSubmit={handleSend} className="flex flex-col gap-3">
              <div>
                <label
                  htmlFor="amount"
                  className="block mb-2 text-sm font-[400] text-white"
                >
                  Token Type:
                </label>
                <Select
                  value={type}
                  onChange={onChange}
                  options={options}
                  isSearchable={true}
                  classNames={{
                    list: "scrollbar",
                    searchBox:
                      "w-full py-2 pl-8 text-sm text-white bg-slate-700 border border-gray-500 rounded focus:border-gray-200 focus:ring-0 focus:outline-none",
                    searchContainer: "relative py-1 px-2.5 text-white",
                    menuButton: ({ isDisabled }) =>
                      `flex text-sm text-white border border-gray-500 rounded shadow-sm transition-all duration-300 focus:outline-none ${
                        isDisabled
                          ? "bg-gray-200"
                          : "bg-slate-900 hover:border-gray-700 focus:border-blue-500 focus:ring focus:ring-blue-500/20"
                      }`,
                    menu: "absolute z-10 w-full bg-slate-800 shadow-lg border border-gray-500 rounded py-1 mt-1.5 text-sm text-white",
                    listItem: ({ isSelected }) =>
                      `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                        isSelected
                          ? `text-white bg-blue-500`
                          : `text-slate-300 hover:bg-blue-100 hover:text-blue-500`
                      }`,
                  }}
                />
              </div>
              <div>
                <label
                  htmlFor="amount"
                  className="block mb-2 text-sm font-[400] text-white"
                >
                  Amount to send:
                </label>
                <input
                  type="number"
                  name="amount2"
                  id="amount"
                  className="text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-gray-600 border-gray-500 placeholder-gray-400 text-white"
                  placeholder="10"
                  required
                  value={amount2}
                  onChange={handleChange}
                />
              </div>
              <button
                type="submit"
                className="btn bg-lime-500 h-[40px] rounded-[6px] mt-4"
              >
                Send Now
              </button>
            </form>
            <div className="mt-10 info text-[13px] border-lime-500 border-l-[2px]">
              &nbsp;&nbsp; It will be sent instantly request.
              <br />
              &nbsp;&nbsp; Your fund is safe
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Account.propTypes = {
  getAccountByUser: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  withdraw: PropTypes.func.isRequired,
  send: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.account,
  errors: state.errors,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  getAccountByUser,
  withdraw,
  send,
  clearErrors,
})(Account);
