import { combineReducers } from 'redux'
import authReducer from './authReducer'
import errorReducer from './errorReducer'
import accountReducer from './accountReducer'
import tokenReducer from './tokenReducer'
import trxReducer from './trxReducer'

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  account: accountReducer,
  token: tokenReducer,
  history: trxReducer,
})
